import APP_CONFIG from "@/apps/core/modules/config.js";

const baseName = "matakuliah"
const baseUrl = "/" + baseName


export default [
  {
    path: baseUrl,
    name: baseName + "-list",
    component: () => import("../views/MataKuliahList.vue"),
    meta: { groups: ["admin", "dosen"], permissions: ["matakuliah.view_matakuliah", "matakuliah.view_kompetensi"] }
  },
  {
    path: baseUrl + '/tambah',
    name: baseName + "-add",
    props: { headerText: "TAMBAH" },
    component: () => import("../views/MataKuliahCreateUpdate.vue"),
    meta: { groups: ["admin", "konsulen"], permissions: ["matakuliah.add_matakuliah"] }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern + '/ubah',
    name: baseName + "-change",
    props: { title: "UBAH"},
    component: () => import("../views/MataKuliahCreateUpdate.vue"),
    meta: { groups: ["admin"], permissions: ["matakuliah.change_matakuliah"] }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern,
    name: baseName + "-detail",
    props: { title: "Mata Kuliah"},
    component: () => import("../views/MataKuliahDetail.vue"),
    meta: { groups: ["admin", "dosen"], permissions: ["matakuliah.view_matakuliah", "matakuliah.view_kompetensi"] }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern + "/modul/tambah",
    name: baseName + "-modul-add",
    component: () => import("../views/ModulInlinesCreate.vue"),
    meta: { groups: ["admin"], permissions: ["matakuliah.add_kompetensi"] }
  },
]
