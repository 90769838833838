import AnggotaBase from "./anggotaBase.js"

const modelName = "anggota";
const modelPath = "/anggota/";

class Anggota extends AnggotaBase {
  constructor() {
    super(modelName, {}, [], [], modelPath);
  }

  initObservables() {
    super.initObservables();
    this.observables.tipe = "admin";
  }
}

export default Anggota;