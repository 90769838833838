const baseName = "penyakit";
const baseUrl = "/" + baseName;


export default [
  {
    path: baseUrl + "/diagnosis/tambah",
    name: "diagnosis-add",
    props: { headerText: "diagnosis", jenisKomponen: "diagnosis" },
    component: () => import("../views/ConcreteKomponenCreate.vue"),
    meta: {
      groups: ["admin", "mahasiswa"],  // PERLU DICEK KEMBALI!!!!
      permissions: ["penyakit.add_diagnosis"]
    }
  },
  {
    path: baseUrl + "/pemeriksaan/tambah",
    name: "pemeriksaan-add",
    props: { headerText: "terapi", jenisKomponen: "pemeriksaan" },
    component: () => import("../views/ConcreteKomponenCreate.vue"),
    meta: {
      groups: ["admin", "mahasiswa"],  // PERLU DICEK KEMBALI!!!!
      permissions: ["penyakit.add_pemeriksaan"]
    }
  },
  {
    path: baseUrl + "/tatalaksana/tambah",
    name: "tatalaksana-add",
    props: { headerText: "pengendalian dan pencegahan", jenisKomponen: "tatalaksana" },
    component: () => import("../views/ConcreteKomponenCreate.vue"),
    meta: {
      groups: ["admin", "mahasiswa"],  // PERLU DICEK KEMBALI!!!!
      permissions: ["penyakit.add_tatalaksana"]
    }
  }
]
