import APP_CONFIG from "@/apps/core/modules/config.js";

const baseName = "klinis"
const baseUrl = "/" + baseName

const basePasienName = "pasien";
const basePasienUrl = "/" + baseName + "/" + basePasienName;


export default [
  // Pasien router
  {
    path: basePasienUrl,
    name: basePasienName + "-list",
    component: () => import("../views/PasienList.vue"),
    meta: { groups: ["admin", "mahasiswa"], permissions: ["perawatan.view_pasien"] }
  },
  {
    path: basePasienUrl + "/tambah",
    name: basePasienName + "-add",
    props: { headerText: "TAMBAH" },
    component: () => import("../views/PasienCreateUpdate.vue"),
    meta: { groups: ["mahasiswa"], permissions: ["perawatan.add_pasien"] } //  mungkin kedepannya admin perlu bisa buat juga
  },
  //  mungkin kedepannya admin perlu bisa edit juga
  // {
  //   path: basePasienUrl + APP_CONFIG.idURLPattern + "/ubah",
  //   name: basePasienName + "-change",
  //   props: { headerText: "UBAH" },
  //   component: () => import("../views/PasienCreateUpdate.vue"),
  //   meta: { groups: ["admin"], permissions: ["perawatan.change_pasien"] }
  // },
  {
    path: basePasienUrl + APP_CONFIG.idURLPattern,
    name: basePasienName + "-detail",
    component: () => import("../views/PasienDetail.vue"),
    meta: {
      groups: ["admin", "dosen", "mahasiswa"],
      permissions: ["perawatan.view_pasien"]
    }
  },

  // Perawatan router
  {
    path: baseUrl,
    name: baseName + "-list",
    component: () => import("../views/PerawatanList.vue"),
    meta: { groups: ["admin", "dosen", "mahasiswa"], permissions: ["perawatan.view_perawatan"] }
  },
  {
    path: baseUrl + "/tambah",
    name: baseName + "-add",
    props: { headerText: "TAMBAH" },
    component: () => import("../views/PerawatanCreateUpdate.vue"),
    meta: { groups: ["mahasiswa"], permissions: ["perawatan.add_perawatan"] }  // perlu dicek lagi
  },
  {
    path: baseUrl + "/lokasi/tambah",
    name: "lokasi-add",
    component: () => import("../views/LokasiCreate.vue"),
    meta: {
      groups: ["mahasiswa"],
      permissions: ["perawatan.add_lokasi"]
    }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern + "/:jenis" + APP_CONFIG.kompIdURLPattern,
    name: baseName + "-aktkomp-detail",
    component: () => import("../views/PerawatanAktKompDetail.vue"),
    meta: { groups: ["admin", "dosen", "mahasiswa"], permissions: ["perawatan.view_perawatan"] }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern,
    name: baseName + "-detail",
    props: { title: 'Perawatan' },
    component: () => import("../views/PerawatanDetail.vue"),
    meta: { groups: ["admin", "dosen", "mahasiswa"], permissions: ["perawatan.view_perawatan"] }
  }
]
