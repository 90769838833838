export default [
  {
    path: "/",
    name: "root",
    redirect: { name: "dashboard"}
    // component: () => import("@/apps/core/views/Home.vue")
  },
  {
    path: "/bantuan",
    name: "bantuan",
    component: () => import("@/apps/core/views/Bantuan.vue"),
    meta: {
      groups: ["admin", "dosen", "mahasiswa"],
      permissions: ["anggota.view_anggota"]
    }
  }
]