import accountRoutes from "@/apps/accounts/modules/router";
import aktivitasRoutes from "@/apps/aktivitas/modules/router";
import anggotaRoutes from "@/apps/anggota/modules/router";
import APP_CONFIG from "@/apps/core/modules/config";
import coreRoutes from "@/apps/core/modules/router";
import matakuliahRoutes from "@/apps/matakuliah/modules/router";
import staseRoutes from "@/apps/stase/modules/router";
import penyakitRoutes from "@/apps/penyakit/modules/router";
import perawatanRoutes from "@/apps/perawatan/modules/router";
import programStudiRoutes from "@/apps/programstudi/modules/router";
import ujianRoutes from "@/apps/ujian/modules/router";
import store from "@/store";
import axios from "axios";
import { has } from "lodash";
import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);

const notFoundRoutes = [
  {
    path: "*",
    name: "not-found",
    meta: { status: "not-found" }
  }
];

const appRoutes = [].concat(
  accountRoutes,
  coreRoutes,
  aktivitasRoutes,
  anggotaRoutes,
  matakuliahRoutes,
  penyakitRoutes,
  programStudiRoutes,
  ujianRoutes,
  staseRoutes,
  perawatanRoutes,
  notFoundRoutes
);


let router = new VueRouter({
  routes: appRoutes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView()
  },
  mode: "history"
});

const userUrl = APP_CONFIG.baseAPIURL + "/anggota/me/";


function checkPermissions(to, next) {
  let hasGroup = true;
  if (has(to.meta, "groups")) {
    hasGroup = to.meta.groups.includes(store.state.accounts.group);
  }

  let hasPerms = true;
  if (has(to.meta, "permissions")) {
    const userPerms = store.state.accounts.permissions;
    const appPerms = to.meta.permissions;
    hasPerms = appPerms.every(perm => userPerms.includes(perm));
  }

  if (!(hasGroup && hasPerms)) {
    to.meta.status = "forbidden";
  }
  next();
}


router.beforeEach((to, from, next) => {
  for (let path of APP_CONFIG.publicPages) {
    if (to.path.match(path) != null) {
      next();
      return;
    }
  }

  const hasToken = store.getters["accounts/isAuthenticated"];
  if (!hasToken) {
    let nextOption = { name: "login" };
    if (to.name !== "root") {
      nextOption.query = { next: to.path };
    }
    next(nextOption);
  } else {
    const noUserId = !store.state.accounts.userId;
    if (hasToken && noUserId) {
      axios
        .get(userUrl)
        .then(response => {
          store.dispatch("accounts/setUser", response.data)
            .then(() => {
              checkPermissions(to, next);
            });
        })
        .catch(error => {
          if (typeof error.response == "undefined") {
            to.meta.status = "offline";
            next();
          }
        });
    } else {
      checkPermissions(to, next);
    }
  }

});


export default router;