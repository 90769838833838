import APP_CONFIG from "@/apps/core/modules/config.js";
import Anggota from "../models/anggotaCU.js";


const baseName = "anggota"
const baseUrl = "/" + baseName


export default [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: {
      groups: ["admin", "dosen", "mahasiswa"],
      permissions: [
        "aktivitas.view_kegiatanpspd",
        // "perawatan.view_perawatan", "ujian.view_ujian",
        // "karyailmiah.view_karyailmiah"
      ]
    }
  },
  {
    path: baseUrl + "/me",
    name: baseName + "-me",
    props: { id: "me" },
    component: () => import("../views/AnggotaDetail.vue"),
    meta: {
      groups: ["admin", "dosen", "mahasiswa"],
      permissions: ["anggota.view_anggota"]
    }
  },
  {
    path: "/mahasiswa/kelompok/tambah",
    name: "kelompok-mahasiswa-add",
    component: () => import("../views/KelompokCreate.vue"),
    meta: {
      groups: ["admin"],
      permissions: ["anggota.add_pspdtag"]
    }
  },
  {
    path: "/mahasiswa/kelompok",
    name: "kelompok-mahasiswa",
    component: () => import("../views/KelompokList.vue"),
    meta: {
      groups: ["admin"],
      permissions: ["anggota.view_pspdtag"]
    }
  },
  {
    path: "/mahasiswa/kelompok" + APP_CONFIG.idURLPattern + "/ubah",
    name: "kelompok-mahasiswa-change",
    component: () => import("../views/KelompokUpdate.vue"),
    meta: {
      groups: ["admin"],
      permissions: ["anggota.change_pspdtag"]
    }
  },
  {
    path: "/mahasiswa/kelompok" + APP_CONFIG.idURLPattern,
    name: "kelompok-mahasiswa-detail",
    component: () => import("../views/KelompokDetail.vue"),
    meta: {
      groups: ["admin"],
      permissions: ["anggota.view_pspdtag"]
    }
  },
  {
    path: baseUrl,
    name: baseName + "-list",
    component: () => import("../views/AnggotaList.vue"),
    meta: { groups: ["admin"], permissions: ["anggota.view_anggota"] }
  },
  {
    path: "/mahasiswa/tambah",
    name: "mahasiswa-add",
    props: { headerText: "TAMBAH" },
    component: () => import("../views/PSPDCreateUpdate.vue"),
    meta: {
      groups: ["admin"],
      permissions: ["anggota.add_anggota", "anggota.add_pspd"]
    }
  },
  {
    path: "/dosen/tambah",
    name: "dosen-add",
    props: { headerText: "TAMBAH" },
    component: () => import("../views/PreceptorCreateUpdate.vue"),
    meta: {
      groups: ["admin"],
      permissions: ["anggota.add_anggota", "anggota.add_preceptor"]
    }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern + "/ubah",
    name: baseName + "-change",
    props: {
      headerText: "UBAH",
      anggotaMdl: new Anggota()
    },
    component: () => import("../views/CommonAnggotaCreateUpdate.vue"),
    meta: {
      groups: ["admin"],
      permissions: ["anggota.change_anggota"]
    }
  },
  {
    path: "/dosen" + APP_CONFIG.idURLPattern + "/ubah",
    name: "dosen-change",
    props: { headerText: "UBAH" },
    component: () => import("../views/PreceptorCreateUpdate.vue"),
    meta: {
      groups: ["admin", "dosen"],
      permissions: ["anggota.change_anggota", "anggota.change_preceptor"]
    }
  },
  {
    path: "/mahasiswa" + APP_CONFIG.idURLPattern + "/ubah",
    name: "mahasiswa-change",
    props: { headerText: "UBAH" },
    component: () => import("../views/PSPDCreateUpdate.vue"),
    meta: {
      groups: ["admin", "mahasiswa"],
      permissions: ["anggota.change_anggota", "anggota.change_pspd"],
      objectPerm: { allowAdmin: true, idField: "id" }
    }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern,
    name: baseName + "-detail",
    component: () => import("../views/AnggotaDetail.vue"),
    meta: { groups: ["admin"], permissions: ["anggota.view_anggota"] }
  }
]
