import APP_CONFIG from "@/apps/core/modules/config.js";

const baseName = "ujian"
const baseUrl = "/" + baseName


export default [
  {
    path: baseUrl,
    name: baseName + "-list",
    component: () => import("../views/UjianList.vue"),
    meta: { groups: ["admin", "dosen", "mahasiswa"], permissions: ["ujian.view_ujian"] }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern,
    name: baseName + "-detail",
    props: { title: "Ujian" },
    component: () => import("../views/UjianDetail.vue"),
    meta: { groups: ["admin", "dosen", "mahasiswa"], permissions: ["ujian.view_ujian"] }
  },
  {
    path: baseUrl + "/tambah",
    name: baseName + "-add",
    component: () => import("../views/UjianCreateUpdate.vue"),
    props: { headerText: "TAMBAH" },
    meta: {
      groups: ["mahasiswa"],
      permissions: ["ujian.add_ujian"]
    }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern + "/ubah",
    name: baseName + "-change",
    component: () => import("../views/UjianCreateUpdate.vue"),
    props: { headerText: "UBAH" },
    meta: {
      groups: ["dosen", "mahasiswa"],
      permissions: ["ujian.change_ujian"]
    }
  }
]
