import APP_CONFIG from "@/apps/core/modules/config.js";

const baseName = "aktivitas";
const baseUrl = "/" + baseName;


export default [
  {
    path: baseUrl + "/tambah",
    name: baseName + "-add",
    props: { headerText: "TAMBAH AKTIVITAS" },
    component: () => import("../views/AktivitasPelayananCreateUpdate.vue"),
    meta: { groups: ["mahasiswa"],
            permissions: ["aktivitas.add_aktivitaspelayanan"] }
  },
  {
    path: baseUrl,
    name: baseName + "-list",
    component: () => import("../views/AktivitasList.vue"),
    meta: { groups: ["admin", "dosen", "mahasiswa"],
            permissions: ["aktivitas.view_aktivitaspelayanan"] }
  },
  {
    path: "/kegiatan-mahasiswa",
    name: "kegiatan-mahasiswa-list",
    component: () => import("../views/KegiatanPSPDList.vue"),
    meta: { groups: ["admin", "dosen", "mahasiswa"],
            permissions: ["aktivitas.view_kegiatanpspd"] }
  },
  {
    path: "/kegiatan-mahasiswa/tambah",
    name: "kegiatan-mahasiswa-add",
    component: () => import("../views/KegiatanPSPDCreateUpdate.vue"),
    meta: { groups: ["mahasiswa"], 
            permissions: ["aktivitas.add_kegiatanpspd"] }
  },
  {
    path: "/kegiatan-mahasiswa" + APP_CONFIG.idURLPattern + "/ubah",
    name: "kegiatan-mahasiswa-change",
    component: () => import("../views/KegiatanPSPDCreateUpdate.vue"),
    meta: { groups: ["mahasiswa"], 
            permissions: ["aktivitas.change_kegiatanpspd"] }
  },
  {
    path: "/kegiatan-mahasiswa" + APP_CONFIG.idURLPattern,
    name: "kegiatan-mahasiswa-detail",
    props: { title: "Kegiatan Harian" },
    component: () => import("../views/KegiatanPSPDDetail.vue"),
    meta: { groups: ["admin", "dosen", "mahasiswa"],
            permissions: ["aktivitas.view_kegiatanpspd"] }
  },
  {
    path: "/pencapaian/mahasiswa",
    name: "pencapaian-mahasiswa",
    component: () => import("../views/PencapaianPSPDList.vue"),
    meta: { groups: ["admin", "dosen"],
            permissions: ["anggota.view_pspd"] }
  },
  {
    path: "/pencapaian/mahasiswa" + APP_CONFIG.idURLPattern,
    name: "pencapaian-mahasiswa-detail",
    component: () => import("../views/PencapaianPSPDDetail.vue"),
    meta: { 
      groups: ["admin", "dosen", "mahasiswa"],
      permissions: [
        "anggota.view_pspd", "aktivitas.view_pencapaiankd", 
        "ujian.view_ujian"] }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern + "/ubah",
    name: baseName + "-change",
    props: { headerText: "UBAH AKTIVITAS" },
    component: () => import("../views/AktivitasPelayananCreateUpdate.vue"),
    meta: { groups: ["mahasiswa"],
            permissions: ["aktivitas.change_aktivitaspelayanan"] }
  },
  {
    path: baseUrl + APP_CONFIG.idURLPattern,
    name: baseName + "-detail",
    props: { title: "Aktivitas Pelayanan"},
    component: () => import("../views/AktivitasDetail.vue"),
    meta: { groups: ["admin", "dosen", "mahasiswa"],
            permissions: ["aktivitas.view_aktivitaspelayanan"] }
  },
  {
    path: baseUrl + APP_CONFIG.aktivitasIdURLPattern + "/diagnosis/tambah",
    name: baseName + "-diagnosis-add",
    props: { headerText: "TAMBAH", komponenTitle: "diagnosis" },
    component: () => import("../views/AktivitasKomponenCreateUpdate.vue"),
    meta: { groups: ["mahasiswa"], permissions: ["aktivitas.add_aktivitasdiagnosis"] }
  },
  {
    path: baseUrl + "/diagnosis" + APP_CONFIG.idURLPattern + "/ubah",
    name: baseName + "-diagnosis-change",
    props: { headerText: "UBAH", komponenTitle: "diagnosis" },
    component: () => import("../views/AktivitasKomponenCreateUpdate.vue"),
    meta: { groups: ["mahasiswa"], permissions: ["aktivitas.change_aktivitasdiagnosis"] }
  },
  {
    path: baseUrl + "/diagnosis" + APP_CONFIG.idURLPattern,
    name: baseName + "-diagnosis-detail",
    props: { title: "Diagnosis", komponenTitle: "DIAGNOSIS" },
    component: () => import("../views/AktivitasKomponenDetail.vue"),
    meta: { groups: ["admin", "dosen", "mahasiswa"], permissions: ["aktivitas.view_aktivitasdiagnosis"] }
  },
  {
    path: baseUrl + APP_CONFIG.aktivitasIdURLPattern + "/pemeriksaan/tambah",
    name: baseName + "-pemeriksaan-add",
    props: { headerText: "TAMBAH", komponenTitle: "pemeriksaan" },
    component: () => import("../views/AktivitasKomponenCreateUpdate.vue"),
    meta: { groups: ["mahasiswa"], permissions: ["aktivitas.add_aktivitaspemeriksaan"] }
  },
  {
    path: baseUrl + "/pemeriksaan" + APP_CONFIG.idURLPattern + "/ubah",
    name: baseName + "-pemeriksaan-change",
    props: { headerText: "UBAH", komponenTitle: "pemeriksaan" },
    component: () => import("../views/AktivitasKomponenCreateUpdate.vue"),
    meta: { groups: ["mahasiswa"], permissions: ["aktivitas.change_aktivitaspemeriksaan"] }
  },
  {
    path: baseUrl + "/pemeriksaan" + APP_CONFIG.idURLPattern,
    name: baseName + "-pemeriksaan-detail",
    props: { title: "Pemeriksaan", komponenTitle: "PEMERIKSAAN" },
    component: () => import("../views/AktivitasKomponenDetail.vue"),
    meta: { groups: ["admin", "dosen", "mahasiswa"],
            permissions: ["aktivitas.view_aktivitaspemeriksaan"] }
  },
  {
    path: baseUrl + APP_CONFIG.aktivitasIdURLPattern + "/tatalaksana/tambah",
    name: baseName + "-tatalaksana-add",
    props: { headerText: "TAMBAH", komponenTitle: "tatalaksana" },
    component: () => import("../views/AktivitasKomponenCreateUpdate.vue"),
    meta: { groups: ["mahasiswa"],
            permissions: ["aktivitas.add_aktivitastatalaksana"] }
  },
  {
    path: baseUrl + "/tatalaksana" + APP_CONFIG.idURLPattern + "/ubah",
    name: baseName + "-tatalaksana-change",
    props: { headerText: "UBAH", komponenTitle: "tatalaksana" },
    component: () => import("../views/AktivitasKomponenCreateUpdate.vue"),
    meta: { groups: ["mahasiswa"],
            permissions: ["aktivitas.change_aktivitastatalaksana"] }
  },
  {
    path: baseUrl + "/tatalaksana" + APP_CONFIG.idURLPattern,
    name: baseName + "-tatalaksana-detail",
    props: { title: "Tatalaksana", komponenTitle: "TATALAKSANA" },
    component: () => import("../views/AktivitasKomponenDetail.vue"),
    meta: { groups: ["admin", "dosen", "mahasiswa"],
            permissions: ["aktivitas.view_aktivitastatalaksana"] }
  }
];
