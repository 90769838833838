import APP_CONFIG from "@/apps/core/modules/config.js";

const state = {
  refreshToken: localStorage.getItem("refreshToken") || "",
  accessToken: localStorage.getItem("accessToken") || "",
  userId: "",
  name: "",
  initial: "",
  group: APP_CONFIG.groupEnum.anonymous,
  permissions: []
};

const getters = {
  isAuthenticated: state => !!state.refreshToken,
  isAdmin: state => state.group === APP_CONFIG.groupEnum.admin,
  // isAdminPusat: state => state.group === APP_CONFIG.groupEnum.admin_pusat,
  // isAdminBagian: state => state.group === APP_CONFIG.groupEnum.admin_bagian,
  isPreceptor: state => state.group === APP_CONFIG.groupEnum.dosen,
  isPSPD: state => state.group === APP_CONFIG.groupEnum.mahasiswa
};

const mutations = {
  setState(mstate, payloadMap) {
    for (let field in payloadMap) {
      mstate[field] = payloadMap[field];
    }
  },
  setUser(mstate, user) {
    mstate.userId = user.id;
    mstate.permissions = user.permissions;
    mstate.name = user.nama;
    mstate.initial = user.inisial;
    const validGroup = Object.keys(APP_CONFIG.groupEnum).includes(user.group);
    mstate.group = validGroup ? user.group : APP_CONFIG.groupEnum.anonymous;
  },
  setAccessToken(mstate, accessToken) {
    mstate.accessToken = accessToken;
    localStorage.setItem("accessToken", accessToken);
  },
  setRefreshToken(mstate, refreshToken) {
    mstate.refreshToken = refreshToken;
    localStorage.setItem("refreshToken", refreshToken);
  },
  resetAllState(mstate) {
    mstate.refreshToken = "";
    mstate.accessToken = "";
    localStorage.setItem("refreshToken", "");
    localStorage.setItem("accessToken", "");
    mstate.userId = "";
    mstate.name = "";
    mstate.initial = "";
    mstate.group = APP_CONFIG.groupEnum.anonymous;
    mstate.permissions = [];
  }
};

const actions = {
  setState({ commit }, payloadMap) {
    commit("setState", payloadMap);
  },
  setUser({ commit }, user) {
    return new Promise((resolve) => {
      commit("setUser", user);
      resolve();
    });
    
  },
  resetAllState({ commit }) {
    commit("resetAllState");
  },
  setAccessToken({ commit }, accessToken) {
    commit("setAccessToken", accessToken);
  },
  setRefreshToken({ commit }, refreshToken) {
    commit("setRefreshToken", refreshToken);
  },
  setToken({ commit }, token) {
    return new Promise((resolve) => {
      commit("setRefreshToken", token.refreshToken);
      commit("setAccessToken", token.accessToken);
      resolve(token.accessToken);
    });
  }
};


export default {
  state,
  getters,
  mutations,
  actions
};
