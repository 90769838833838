import accounts from "@/apps/accounts/modules/store";
import aktivitas from "@/apps/aktivitas/modules/store";
import anggota from "@/apps/anggota/modules/store";
import core from "@/apps/core/modules/store";
import perawatan from "@/apps/perawatan/modules/store";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    accounts: {
      namespaced: true,
      ...accounts
    },
    aktivitas: {
      namespaced: true,
      ...aktivitas
    },
    anggota: {
      namespaced: true,
      ...anggota
    },
    core: {
      namespaced: true,
      ...core
    },
    perawatan: {
      namespaced: true,
      ...perawatan
    }
  }
});
