import APP_CONFIG from "@/apps/core/modules/config.js";


export default [
  {
    path: "/login",
    name: "login",
    component: () => import("@/apps/accounts/views/Login.vue"),
    meta: { status: "login" }
  },
  {
    path: "/request-reset-password",
    name: "request-reset-password",
    component: () => import("@/apps/accounts/views/RequestResetPassword.vue"),
    meta: { status: "request-reset-password" }
  },
  {
    path: "/reset-password" + APP_CONFIG.idURLPattern,
    name: "reset-password",
    component: () => import("@/apps/accounts/views/ResetPassword.vue"),
    meta: { status: "reset-password" }
  },
  {
    path: "/ubah-sandi",
    name: "change-password",
    component: () => import("@/apps/accounts/views/ChangePassword.vue"),
    meta: {
      status: "change-password",
      groups: ["admin", "dosen", "mahasiswa"],
      permissions: ["anggota.view_anggota"]
    }
  }
]